<template>
  <div id="app" class="app">
    <router-view></router-view>
  </div>
</template>
<script>
import { ref, reactive, onMounted, inject } from "vue";
export default {
  setup() {
    const tran = ref(null);
    onMounted(() => {

    });

    return {
      tran,
    };
  },
};
</script>
<style scoped>
.app {
  height: 0;
}
</style>