import { createRouter, createWebHashHistory } from "vue-router";
const routes = [
    {
        path: '/',
        component: () => import('../views/chayuan')
    }, {
        path: '/qiye',
        component: () => import('../views/qiye')
    },{
        path: '/shengxian',
        component: () => import('../views/shengxian')
    }
];
const router = createRouter({
    history: createWebHashHistory(),
    routes
});
export default router;