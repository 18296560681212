import { createApp } from 'vue'
import router from './router';//添加router
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import axios from 'axios'

const app = createApp(App)
// var token = 0
// window.parent.postMessage("加载完成", "http://localhost:8080");
// window.addEventListener("message", (ev) => {
//   if (ev.data.data != undefined) {
//     token = ev.data.data;
//   }

// });
// function getState(url, params) {
//   return axios.get(url, {
//     params: params,
//     headers: {
//       "Tea-IISP-SYS-Auth": token
//     },
//   });
// }

app.use(ElementPlus).use(router).mount('#app')




